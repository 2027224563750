const toggleClasses = {
	bodyNavVisible: 'nav-visible',
}

class Nav
{
    constructor()
    {
		this.nav = document.querySelector('.js-nav');
		this.toggle = document.querySelector('.js-nav__trigger');

		this.menuOpen = false;
		this.init();
    }

    init()
    {
		this.toggle.addEventListener('click', e => {
			this.menuOpen = !this.menuOpen;
			if (this.menuOpen) {
				this.show();
			} else {
				this.hide();
			}
			e.stopPropagation();
		});

		this.nav.addEventListener('click', e => {
			e.stopPropagation();
		});

		document.body.addEventListener('click', e => {
			this.hide();
		});
	}

	show()
	{
		document.body.classList.add(toggleClasses.bodyNavVisible);
	}

	hide()
	{
		document.body.classList.remove(toggleClasses.bodyNavVisible);
	}
}

export default Nav;
