import 'fetch-polyfill';

import { checkHttpResponseStatus } from '../../libs/util';

import validateForm from './validateForm';


class Form
{
    constructor()
    {
        this.form = document.querySelector('.js-form');
        if (!this.form) return;

        this.submit = this.form.querySelector('.js-form__submit');
        //this.sentMsg = this.form.querySelector('.js-form__sent-msg');

        this.form.addEventListener('submit', e =>
        {
            e.preventDefault();

            this.submit.classList.remove('form__submit--error');
            this.submit.classList.remove('form__submit--success');

            if (validateForm(this.form))
            {
                grecaptcha.execute();
            }
            else
            {
                this.submit.classList.add('form__submit--error');
                setTimeout(e => this.submit.classList.remove('form__submit--error'), 5000);
            }
        });
    }


    doSubmit()
    {
        this.submit.classList.add('btn--disabled');

        fetch(this.form.getAttribute('action'), {
            method: 'POST',
            body: new FormData(this.form)
        })
            .then(checkHttpResponseStatus)
            .then(response => response.json())
            .then(response => this.onSubmitSuccess(response))
            .catch(error => this.handleError(error));
    }

        onSubmitSuccess(response)
        {
            this.submit.classList.remove('btn--disabled');

            if (response.success)
            {
				if (response.message === 'viernulnul') {
					this.form.insertAdjacentHTML('beforeend', 'Het formulier is niet verzonden.');
					this.submit.parentElement.removeChild(this.submit);
				} else {
					this.form.reset();
					this.submit.classList.add('form__submit--success');
					setTimeout(e => this.submit.classList.remove('form__submit--success'), 5000);
				}
            }
            else
            {
                const error = new Error(response.message);
                error.response = response;
                throw error;
            }
            grecaptcha.reset();
        }

        handleError(error)
        {
            grecaptcha.reset();
			alert(error.message);
        }
}

export default Form;
