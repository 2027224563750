import 'babel-polyfill';
import picturefill from 'picturefill';

import '../libs/Modernizr';

import UiHelper from './modules/UiHelper';
import Nav from './modules/Nav';
import Search from './modules/Search';
import Form from './modules/Form';
import CookieManager from './modules/CookieManager';

function onDocumentReady()
{
  new Nav();
	new Search();

  UiHelper.init();
	const f = new Form();
	window.onContactFormSubmit = () => {
		f.doSubmit()
	}
	
	CookieManager();
}

//call onDocumentReady()
if (document.readyState != 'loading') onDocumentReady();
else document.addEventListener('DOMContentLoaded', onDocumentReady);
