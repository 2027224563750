import picturefill from 'picturefill';
import imagesLoaded from 'imagesloaded';

import { debounce, uuid } from './util.js';


class BgData
{
    constructor(selector = '[data-bg-srcset]')
    {
        this.cleanUpTimeout = null;

        const els = document.querySelectorAll(selector);
        this.items = Array.from(els).map(el => ({ el, dummyImage: null }));
        this.itemsToProcess = [];

        this.update();
        this.initOnResize();

		this.tries = [];
    }

    update()
    {
        if (this.cleanUpTimeout !== null) clearTimeout(this.cleanUpTimeout);

        const winWidth = window.innerWidth;

        const dummyContainer = document.createElement("div");
        dummyContainer.style.display = 'none';
        dummyContainer.id = `bgdata-dummy-${uuid()}`;
        dummyContainer.classList.add('js-bgdata-dummy');

        const imgs = [];

        this.itemsToProcess = this.items.reduce((collection, item) => {

            //Only handle visible items
            //If element is visible it has an offsetParent (*** WARNING: THIS CHECK DOESN'T WORK FOR POSITION: FIXED ITEMS! ***)
            if (item.el.offsetParent)
            {
                const dummyImage = new Image();
                dummyImage.setAttribute('sizes', item.el.dataset.bgSizes);
                dummyImage.setAttribute('srcset', item.el.dataset.bgSrcset);

                imgs.push(dummyImage);

                dummyContainer.appendChild(dummyImage);

                collection.push({ el: item.el, dummyImage});
            }

            return collection;
        }, []);

        document.body.appendChild(dummyContainer);

        picturefill({
            elements: imgs
        });

        imagesLoaded(dummyContainer, instance =>
        {
            this.itemsToProcess.forEach(({ el, dummyImage }) => {
                const img = dummyImage.currentSrc || dummyImage.src;
				if (img)
				{
					el.style.backgroundImage = `url(${img})`;
				}
				else
				{
					dummyImage.id = `bgdata-img-dummy-${uuid()}`;
					this.tries[dummyImage.id] = 0;
					setTimeout(({ el, dummyImage }) => { this.setImageByDummy({ el, dummyImage }); }, 100, { el, dummyImage });
				}
            });
            //this.cleanUpTimeout = setTimeout(this.cleanUp, 5000);
            //this.cleanUp();
        });
    }

    initOnResize()
    {
        const debouncedUpdate = debounce(() => this.update(), 500);
        window.addEventListener('resize', debouncedUpdate);
    }

    cleanUp()
    {
        const dummyContainers = document.querySelectorAll('.js-bgdata-dummy');
        Array.from(dummyContainers).forEach(dummyContainer => {
            dummyContainer.parentNode.removeChild(dummyContainer);
        });
    }

	setImageByDummy({ el, dummyImage})
	{
		this.tries[dummyImage.id]++;

		const img = dummyImage.currentSrc || dummyImage.src;
		if (img)
		{
			el.style.backgroundImage = `url(${img})`;
		}
		else
		{
			if (this.tries[dummyImage.id] < 20)
			{
				//retry later
				setTimeout(({ el, dummyImage }) => { this.setImageByDummy({ el, dummyImage }); }, 100, { el, dummyImage });
			}
		}
	}

}

export default BgData;
