const toggleClasses = {
	bodySearchActive: 'search-active',
	formVisible: 'header-search--visible',
	formFocus: 'header-search--focus',
}

class Search
{
    constructor()
    {
		this.nav = document.querySelector('.js-nav');
		this.form = document.querySelector('.js-header-search');
		this.input = document.querySelector('.js-header-search__input');
		this.toggle = document.querySelector('.js-header-search__trigger');
		this.cancel = document.querySelector('.js-header-search__cancel');
		
		this.init();
    }

    init()
    {
        this.toggle.addEventListener('click', e => {
			this.show();
		});

		this.input.addEventListener('blur', e => this.hide());
		this.cancel.addEventListener('click', e => this.hide());

		// close on esc
		document.addEventListener('keyup', e => {
			if (e.keyCode == 27) { this.hide(); }
		});
	}

	show() {
		document.body.classList.add(toggleClasses.bodySearchActive);
		this.form.classList.add(toggleClasses.formVisible);
		setTimeout(() => {
			this.form.classList.add(toggleClasses.formFocus);
			this.input.focus();
		}, 10);
		setTimeout(() => {
			this.input.setAttribute('placeholder', window.innerWidth > 768 ? this.input.dataset.placeholder : this.input.dataset.placeholderXs);
		}, 200);
	}
		

	hide() {
		document.body.classList.remove(toggleClasses.bodySearchActive);
		this.form.classList.remove(toggleClasses.formFocus);
		this.input.removeAttribute('placeholder');
		setTimeout(() => {
			this.form.classList.remove(toggleClasses.formVisible);
		}, 200);
	}
}

export default Search;
