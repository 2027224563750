// https://medium.com/@_jh3y/throttling-and-debouncing-in-javascript-b01cad5c8edf
export function throttle(func, limit)
{
    let inThrottle,
        lastFunc,
        throttleTimer;
    return function()
    {
        const context = this,
              args = arguments;
        if (inThrottle)
        {
            clearTimeout(lastFunc);
            return lastFunc = setTimeout(function()
            {
                func.apply(context, args);
                inThrottle = false;
            }, limit);
        }
        else
        {
            func.apply(context, args);
            inThrottle = true;
            return throttleTimer = setTimeout(function()
            {
                return inThrottle = false;
            }, limit);
        }
    };
};

// https://medium.com/@_jh3y/throttling-and-debouncing-in-javascript-b01cad5c8edf
export function debounce(func, delay)
{
    let inDebounce = undefined;
    return function()
    {
        const context = this,
              args = arguments;
        clearTimeout(inDebounce);
        return inDebounce = setTimeout(function()
        {
            return func.apply(context, args);
        }, delay);
    }
}

export function uuid()
{
    //http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function")
    {
        d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c)
    {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
}


export function checkHttpResponseStatus(response) //response should be a Promise!
{
    //console.log('checkHttpResponseStatus', response.status);
    if (response.status >= 200 && response.status < 300)
    {
        return response;
    }
    else
    {
        var error = new Error(response.statusText);
        error.response = response;
        throw error;
    }
}


export function removeElementsBySelector(selector, parent=document.body)
{
    Array.from(parent.querySelectorAll(selector)).forEach(el => { el.parentNode.removeChild(el); });
}
