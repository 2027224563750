import validation from '../../libs/validation';
//import { removeElementsBySelector } from '../../libs/util';

//import { validationMessages } from '../config';

function validateForm(form)
{
    //remove validation classes from previous run
    Array.from(form.querySelectorAll('.form__input--error')).forEach(input => {
        input.classList.remove('form__input--error')
    });
    Array.from(form.querySelectorAll('.form__label--error')).forEach(label => {
        label.classList.remove('form__label--error')
    });

    //remove validation messages from previous run
    //removeElementsBySelector('.js-validation-message', form);

    const inputsToValidate = Array.from(form.querySelectorAll('.js-do-validate'));

    const validationResults = inputsToValidate.map(field => validateField(field));

    return validationResults.every(res => res);
}
export default validateForm;


function validateField(field)
{
    if (!field.dataset.validation) return true;

    for (let validationType of field.dataset.validation.split(','))
    {
        validationType = validationType.trim();
        if (validation[validationType] && !validation[validationType](field.value))
        {
            //mark field
            //field.parentNode.insertAdjacentHTML('beforeend', `<p class="js-validation-message validation-message">${validationMessages[validationType]}</p>`);
            field.classList.add('form__input--error');
            //mark label
            field.parentNode.parentNode.querySelector('.form__label').classList.add('form__label--error');

            return false;
        }
    }

    return true;
}
