export function validateRequired(val)
{
    return val != '';
}

export function validateEmail(val)
{
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
	return reg.test(val);
}

const validation = {
    required: validateRequired,
    email: validateEmail
}
export default validation;
