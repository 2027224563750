import Cookies from 'js-cookie';
import 'fetch-polyfill';

import { checkHttpResponseStatus } from '../../libs/util';

const config = {
	cookieName: 'allowCookies',
	selectors: {
		cookiebar: '.js-cookiebar',
		acceptBtn: '.js-cookiebar__accept',
		blockedContent: '.js-blocked-content',
		blockedContentAcceptBtn: '.js-blocked-content__accept',
		toggle: '.js-cookie-setting-toggle'
	},
	cookiebarVisibleClass: 'cookiebar--visible',
	blockedContentLoadingClass: 'blocked-content--loading'
}

const cookiebar = document.querySelector(config.selectors.cookiebar);
const acceptBtn = cookiebar.querySelector(config.selectors.acceptBtn);

const blockedContent = document.querySelectorAll(config.selectors.blockedContent);
const blockedContentAcceptBtns = document.querySelectorAll(config.selectors.blockedContentAcceptBtn);

const toggle = document.querySelector(config.selectors.toggle);

function init()
{
	const currentSetting = Cookies.get(config.cookieName);
	
	if (currentSetting === undefined)
    {
        //set timeout for animation
        setTimeout(() => {
            cookiebar.classList.add(config.cookiebarVisibleClass)
        }, 500);

        // accept
		acceptBtn.addEventListener('click', () => setCookie(true));
	}
	
	// blocked content accept
	Array.from(blockedContentAcceptBtns).forEach(btn => {
		btn.addEventListener('click', () => setCookie(true));
	});

	if (toggle) {
		if (currentSetting == 'true') {
			toggle.checked = true;
		}
		toggle.addEventListener('change', () => setCookie(toggle.checked));
	}
}

function setCookie(v)
{
	Cookies.set(config.cookieName, v, { expires: 365 });
	cookiebar.classList.remove(config.cookiebarVisibleClass);

	if (toggle && v) {
		toggle.checked = true;
	}

	if (blockedContentAcceptBtns.length && v)
	{
		// init loading
		Array.from(blockedContent).forEach(bc => bc.classList.add(config.blockedContentLoadingClass))

		// replace blocked content with actual content
		var headers = new Headers();
		headers.append('pragma', 'no-cache');
		headers.append('cache-control', 'no-cache');
		fetch(`${window.location.href}?allowCookies=true`, { headers, cache: 'no-cache' })
			.then(checkHttpResponseStatus)
			.then(response => response.text())
            .then(replaceBlockedContent)
			.catch(error => handleError(error));
	}
}

function replaceBlockedContent(pageHtml) {
	const parser = new DOMParser();
	const fetchedDoc = parser.parseFromString(pageHtml, "text/html");
	
	Array.from(blockedContent)
		.map(el => el.dataset.blocked)
		.forEach(bcId => {
			const selector = `.js-bc-${bcId}`;
			if (fetchedDoc.querySelector(selector).innerHTML.indexOf('blocked-content') >= 0)
			{
				// somehow cache busting didn't work; refresh the page
				window.location.reload();
			}
			else
			{
				document.querySelector(selector).innerHTML = fetchedDoc.querySelector(selector).innerHTML;
			}
		});
}

function handleError(error)
{
	alert(error);
}

window.deleteCookie = () =>
{
	Cookies.remove(config.cookieName);
}

export default init;