import svg4everybody from 'svg4everybody';

import BgData from '../../libs/BgData';
import { throttle } from '../../libs/util'

import Nav from './Nav';

class UiHelper
{
    static init()
    {
        svg4everybody();

        new BgData();

        this.setScrollListener();

        this.initDotLinks();
    }

    static setScrollListener()
    {
		this.header = document.querySelector('.site-header');

        const scrollListener = e =>
        {
            this.setHeaderScroll();
            Array.from(document.querySelectorAll('.js-dot-link')).forEach(link =>
            {
                link.classList.remove('dot-link--hover');
            });
        };
        const throttledScroll = throttle(scrollListener, 20);
        window.addEventListener('scroll', throttledScroll);
        this.setHeaderScroll();
    }

        static setHeaderScroll()
        {
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
            if (scrollTop > 0)
            {
                this.header.classList.add('site-header--scrolled');
            }
            else
            {
                this.header.classList.remove('site-header--scrolled');
			}
			if (scrollTop > 52)
            {
                this.header.classList.add('site-header--scrolled-past-top-bar');
            }
            else
            {
                this.header.classList.remove('site-header--scrolled-past-top-bar');
            }
        }


    static initDotLinks()
    {
        if (Modernizr.touchevents)
        {
            Array.from(document.querySelectorAll('.js-dot-link')).forEach(link =>
            {
                link.classList.remove('dot-link--hover');

                link.addEventListener('click', e =>
                {
                    e.preventDefault();
                    const link = e.currentTarget;
                    link.classList.add('dot-link--hover');
                    const linkHref = link.getAttribute('href');
                    setTimeout(() =>
                    {
                        window.location = linkHref;
                    }, 250);
                });
            });
        }
    }
}

export default UiHelper;
